<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form>
          <v-card-text>
            <v-container>
              <v-layout
                wrap
                justify-center
                v-for="(essay, e) in essayQuestions"
                :key="e"
              >
                <v-flex xs10 sm11 pa-2>
                  <v-card>
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12>
                        <v-text-field
                          outlined
                          dense
                          v-model="essay.question"
                          label="Question"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-textarea
                          outlined
                          dense
                          rows="10"
                          v-model="essay.answer"
                          label="Answer"
                          required
                        ></v-textarea>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                  <v-icon color="red" @click="removeEssayQuestion(e)"
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end pt-5>
                <v-flex text-right>
                  <v-btn
                    small
                    dark
                    @click="addEssayQuestion()"
                    style="font-family: poppinsmedium"
                    color="green"
                    >Add More Questions</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" block dark @click="add()"> ADD QUESTIONS </v-btn>
          </v-card-actions>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      essayQuestions: [
        {
          question: "",
          answer: "",
        },
      ],
      selectedOption: null,
    };
  },
  methods: {
    addEssayQuestion() {
      const newOption = {
        question: " ",
        answer: "",
      };
      this.essayQuestions.push(newOption);
    },
    removeEssayQuestion(e) {
      this.essayQuestions.splice(e, 1);
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["question"] = this.essayQuestions;
      user["courseId"] = this.$route.query.id;

      axios({
        url: "/question/add/essay/course",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            // this.getData();
            this.$router.push("/questionView?id=" + this.$route.query.id);
            this.dialog = false;
            this.questions = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>