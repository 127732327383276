<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form>
          <v-card-text>
            <v-container>
              <v-layout
                wrap
                justify-center
                v-for="(list, l) in questions"
                :key="l"
              >
                <v-flex xs10 sm11 pa-2>
                  <v-card>
                    <v-layout wrap justify-center pa-5>
                      <v-flex xs12>
                        <v-text-field
                          outlined
                          dense
                          v-model="list.question"
                          label="Question"
                          required
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex
                            xs12
                            sm12
                            pt-2
                            v-for="(item, i) in list.options"
                            :key="i"
                          >
                            <v-layout wrap>
                              <v-flex xs2 sm1 pt-2>
                                <v-checkbox
                                  v-model="item.isCorrect"
                                  hide-details
                                  class="shrink mr-2 mt-0"
                                  @change="handleCheckboxChange(item,l)"
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs8 sm10 text-left>
                                <v-text-field
                                  dense
                                  v-model="item.option"
                                  label="Option"
                                  required
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs2 sm1 pt-4 text-right>
                                <v-icon
                                  small
                                  color="red"
                                  @click="removeOption(l, i)"
                                  >mdi-close</v-icon
                                >
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                        <v-flex text-right pt-5>
                          <v-btn
                            small
                            dark
                            @click="addOption(l)"
                            color="green"
                            style="font-family: poppinsmedium"
                            >Add Option</v-btn
                          >
                        </v-flex>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs2 sm1 text-right>
                  <v-icon color="red" @click="removeQuestion(l)"
                    >mdi-close</v-icon
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap justify-end pt-5>
                <v-flex text-right>
                  <v-btn
                    small
                    dark
                    @click="addQuestion()"
                    style="font-family: poppinsmedium"
                    color="green"
                    >Add More Questions</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" block dark @click="add()"> ADD QUESTIONS </v-btn>
          </v-card-actions>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      questions: [
        {
          question: "",
          options: [{ option: "", isCorrect: false }],
        },
      ],
      selectedOption: null,
    };
  },
  methods: {
    handleCheckboxChange(selectedItem, l) {
      // Uncheck all other checkboxes within the same question block
      this.questions[l].options.forEach((item) => {
        if (item !== selectedItem) {
          item.isCorrect = false;
        }
      });
    },
    addOption(l) {
      // const newOptionId = this.options.length + 1;
      const newOption = { option: " ", isCorrect: false };
      this.questions[l].options.push(newOption);
    },
    addQuestion() {
      const newOption = {
        question: " ",
        options: [{ option: " ", isCorrect: false }],
      };
      this.questions.push(newOption);
    },
    removeOption(l, i) {
      this.questions[l].options.splice(i, 1);
    },
    removeQuestion(l) {
      this.questions.splice(l, 1);
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["questions"] = this.questions;
      user["courseId"] = this.$route.query.id;

      axios({
        url: "/question/add/course",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            // this.getData();
            this.$router.push("/questionView?id=" + this.$route.query.id);
            this.dialog = false;
            this.questions = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>