<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title
            class="elevation-1"
            style="font-family: poppinsextrabold"
          >
            Add Questions
            <v-spacer></v-spacer>
            <v-flex text-right>
              <v-btn color="#3CB043" large block @click="dialogExcel = true">
                <v-layout wrap>
                  <v-flex xs12 py-5>
                    <v-icon color="white">mdi-file-upload</v-icon>
                    &nbsp;
                    <span
                      style="
                        font-family: opensansregular;
                        color: #ffffff;
                        font-size: 14px;
                        text-transform: none;
                      "
                      >Upload Questions</span
                    >
                  </v-flex>
                </v-layout>
              </v-btn>
            </v-flex>
            <v-dialog v-model="dialogExcel" width="700px" persistent>
              <v-layout wrap px-4 justify-end style="background-color: white">
                <v-flex xs11 text-left align-self-center py-10 px-5>
                  <!-- <span
                style="
                  font-family: poppinsmedium;
                  font-size: 16px;
                  color: #000000;
                "
                >Note : Please upload an Excel file with "Sheet1"
                containing columns; "Name," "Email," "Country Code,"
                "Phone," and "Gender" in the correct format.</span
              > -->
                  <v-layout wrap>
                    <v-flex xs12 pt-4>
                      <v-btn
                        block
                        small
                        dark
                        height="50px"
                        color="#3CB043"
                        @click="$refs.showFileMCQ.click()"
                      >
                        <v-icon style="color: #ffffff"
                          >mdi-note-text-outline</v-icon
                        >
                        <span style="font-family: poppinssemibold"
                          >UPLOAD MCQ QUESTION SHEET</span
                        >
                      </v-btn>
                      <input
                        v-show="false"
                        id="fileMCQ"
                        ref="showFileMCQ"
                        multiple="multiple"
                        type="file"
                        @change="showFileMCQ"
                      />
                      <v-layout justify-center wrap pt-2>
                        <v-flex xs12 text-center>
                          <span
                            style="font-family: poppinsregular; color: #8f8f8f"
                            >Upload One or Multiple MCQ Question</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-4>
                      <v-btn
                        block
                        small
                        dark
                        height="50px"
                        color="#3CB043"
                        @click="$refs.showFileTrueORfalse.click()"
                      >
                        <v-icon style="color: #ffffff"
                          >mdi-note-text-outline</v-icon
                        >
                        <span style="font-family: poppinssemibold"
                          >UPLOAD TRUE/FALSE QUESTION SHEET</span
                        >
                      </v-btn>
                      <input
                        v-show="false"
                        id="fileTrueORFalse"
                        ref="showFileTrueORfalse"
                        multiple="multiple"
                        type="file"
                        @change="showFileTrueORfalse"
                      />
                      <v-layout justify-center wrap pt-2>
                        <v-flex xs12 text-center>
                          <span
                            style="font-family: poppinsregular; color: #8f8f8f"
                            >Upload One or Multiple True/False Question</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-4>
                      <v-btn
                        block
                        small
                        dark
                        height="50px"
                        color="#3CB043"
                        @click="$refs.showFileEssay.click()"
                      >
                        <v-icon style="color: #ffffff"
                          >mdi-note-text-outline</v-icon
                        >
                        <span style="font-family: poppinssemibold"
                          >UPLOAD ESSAY QUESTION SHEET</span
                        >
                      </v-btn>
                      <input
                        v-show="false"
                        id="fileEssay"
                        ref="showFileEssay"
                        multiple="multiple"
                        type="file"
                        @change="showFileEssay"
                      />
                      <v-layout justify-center wrap pt-2>
                        <v-flex xs12 text-center>
                          <span
                            style="font-family: poppinsregular; color: #8f8f8f"
                            >Upload One or Multiple Essay Question</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs1 pt-2 align-self-start @click="dialogExcel = false">
                  <v-icon>mdi-close-circle</v-icon>
                </v-flex>
              </v-layout>
            </v-dialog>
            <v-flex text-right>
              <v-btn
                dark
                color="green"
                :to="'/questionView?id=' + $route.query.id"
              >
                <span style="color: white"> View Questions </span>
              </v-btn>
            </v-flex>
          </v-card-title>
          <div class="pt-4">
            <v-tabs
              v-model="tab"
              background-color="#DFFFDBCC"
              color="#3CB043"
              dark
              grow
            >
              <v-tab v-for="(item, i) in items" :key="i">
                <span
                  style="font-family: poppinsmedium"
                  :style="tab == i ? 'color: black' : 'color: #635E5E'"
                >
                  {{ item }}</span
                >
              </v-tab>
              <v-tab-item>
                <McqQuestion />
              </v-tab-item>
              <v-tab-item>
                <EssayQuestion />
              </v-tab-item>
              <v-tab-item>
                <TrueORfalseQuestion />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import McqQuestion from "./mcqQuestion";
import EssayQuestion from "./essayQuestion";
import TrueORfalseQuestion from "./trueORfalseQuestion";
export default {
  components: {
    McqQuestion,
    EssayQuestion,
    TrueORfalseQuestion,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      dialog: false,
      dialogExcel: false,
      itemid: "",
      category: [],
      categoryView: "",
      itemediting: [],
      moduleView: [],
      radioGroup: "",
      data: [],
      filePreviewMCQ: false,
      showFilePreviewMCQ: false,
      filePreviewEssay: false,
      showFilePreviewEssay: false,
      filePreviewTrueORFalse: false,
      showFilePreviewTrueORFalse: false,
      fileMCQ: null,
      fileTrueORFalse: null,
      fileEssay: null,
      tab: null,
      items: ["MCQ", "Essay", "True/False"],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      questions: [
        {
          question: "",
          options: [{ option: "", isCorrect: false }],
        },
      ],
      essayQuestions: [
        {
          questions: "",
          answer: "",
        },
      ],
      selectedOption: null,
    };
  },
  mounted() {
    // this.getData();
  },
  methods: {
    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     url: "/module/view",
    //     method: "GET",
    //     params: {
    //       id: this.$route.query.id,
    //     },
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       this.moduleView = response.data.module;
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    addOption(l) {
      // const newOptionId = this.options.length + 1;
      const newOption = { option: " ", isCorrect: false };
      this.questions[l].options.push(newOption);
    },
    addQuestion() {
      const newOption = {
        question: " ",
        options: [{ option: " ", isCorrect: false }],
      };
      this.questions.push(newOption);
    },
    addEssayQuestion() {
      const newOption = {
        question: " ",
        options: [{ option: " ", isCorrect: false }],
      };
      this.questions.push(newOption);
    },
    removeOption(l, i) {
      this.questions[l].options.splice(i, 1);
    },
    removeQuestion(l) {
      this.questions.splice(l, 1);
    },
    removeEssayQuestion(e) {
      this.essayQuestions.splice(e, 1);
    },
    add() {
      this.appLoading = true;
      var user = {};
      user["questions"] = this.questions;
      user["moduleId"] = this.$route.query.id;

      axios({
        url: "/question/add",
        method: "POST",
        data: user,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialog = false;
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            // this.getData();
            this.$router.push("/questionView?id=" + this.$route.query.id);
            this.dialog = false;
            this.questions = [];
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    showFileMCQ(e) {
      this.fileMCQ = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.filePreviewMCQ = e.target.result;
      };
      if (this.fileMCQ.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showFilePreviewMCQ = true;
      if (this.fileMCQ) {
        if (/\.(jpe?g|png|gif)$/i.test(this.fileMCQ.name)) {
          reader.readAsDataURL(this.fileMCQ);
        }
        this.uploadFileMCQ();
      }
    },
    uploadFileMCQ() {
      let formData = new FormData();
      formData.append("excelFile", this.fileMCQ);
      formData.append("courseId", this.$route.query.id);
      axios({
        method: "POST",
        url: "/upload/mcq/excel/course",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showsnackbar = true;
            this.dialogExcel = false;
            this.fileMCQ = null;
            this.filePreviewMCQ = null;
            this.showFilePreviewMCQ = null;
            this.$router.push("/questionView?id=" + this.$route.query.id);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    showFileTrueORfalse(e) {
      this.fileTrueORFalse = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.filePreviewTrueORFalse = e.target.result;
      };
      if (this.fileTrueORFalse.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showshowsnackbar = true;
        return;
      }
      this.showFilePreviewTrueORFalse = true;
      if (this.fileTrueORFalse) {
        if (/\.(jpe?g|png|gif)$/i.test(this.fileTrueORFalse.name)) {
          reader.readAsDataURL(this.fileTrueORFalse);
        }
        this.uploadFileTrueORfalse();
      }
    },
    uploadFileTrueORfalse() {
      let formData = new FormData();
      formData.append("excelFile", this.fileTrueORFalse);
      formData.append("courseId", this.$route.query.id);
      axios({
        method: "POST",
        url: "/upload/trueOrFalse/excel/course",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showsnackbar = true;
            this.dialogExcel = false;
            this.fileTrueORFalse = null;
            this.filePreviewTrueORFalse = null;
            this.showFilePreviewTrueORFalse = null;
            this.$router.push("/questionView?id=" + this.$route.query.id);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    showFileEssay(e) {
      this.fileEssay = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.filePreviewEssay = e.target.result;
      };
      if (this.fileEssay.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showsnackbar = true;
        return;
      }
      this.showFilePreviewEssay = true;
      if (this.fileEssay) {
        if (/\.(jpe?g|png|gif)$/i.test(this.fileEssay.name)) {
          reader.readAsDataURL(this.fileEssay);
        }
        this.uploadFileEssay();
      }
    },
    uploadFileEssay() {
      let formData = new FormData();
      formData.append("excelFile", this.fileEssay);
      formData.append("courseId", this.$route.query.id);
      axios({
        method: "POST",
        url: "/upload/eassy/excel/course",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Uploaded Successully";
            this.showsnackbar = true;
            this.dialogExcel = false;
            this.fileEssay = null;
            this.filePreviewEssay = null;
            this.showFilePreviewEssay = null;
            this.$router.push("/questionView?id=" + this.$route.query.id);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showsnackbar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 18px;
  font-family: poppinssemibold;
}

.item_value {
  font-family: poppinsmedium;
  font-size: 16px;
}
</style>
  